export const ALL_DRIVER_FOR_FLEET_COLUMNS = ['accountStatus', 'driverImages', 'driverName', 'driverId', 'email', 'actions'];

export const ALL_DRIVER_FOR_FLEET_COLUMNS_USERTYPE_EMAIL_FALSE = (isFaceIdEnabled) => [
  ...(isFaceIdEnabled ? ['driverImages'] : ''),
  'driverName',
  'driverId',
  'email',
  'actions',
];

export const DRIVERS_LIST_COLUMNS = (isFaceIdEnabled) => [
  'accountStatus',
  ...(isFaceIdEnabled ? ['driverImages'] : ''),
  'driverName',
  'driverId',
  'email',
  'actions',
];

export const EXTERNAL_DRIVER_LIST_COLUMNS = ['driverName', 'driverId', 'actions'];

export const AI_RECOGNIZED_EVENTS_COLUMNS = ['recognized-events', 'average-action', 'total-actions'];

export const DRIVER_TABLE_PAGE_SIZE = 5;

export const DRIVER_TYPE_LIST = [
  {
    label: 'Manual Addition',
    value: 'manualAddition',
  },
  {
    label: 'External Source',
    value: 'externalSource',
  },
];
export const DRIVERS_PAGE_FILTER_OPTIONS = [
  { Label: 'All', value: 'ALL' },
  { Label: 'Driver', value: 'DRIVER' },
  { Label: 'Installer', value: 'INSTALLER' },
];
export const PAGINATE_SIZES = [5, 10, 15, 20];

export const BOOKMARKED_VIDEOS_TABLE_COLUMNS = [
  'eventType',
  'coachingInitiatedBy',
  'coachingCompletedBy',
  'eventTime',
  'driverName',
  'status',
  'actions',
];

export const CHALLENGE_INCIDENTS_TABLE_COLUMNS = ['eventType', 'challengeResolvedBy', 'eventTime', 'driverName', 'status', 'actions'];

export const DRIVER_PANIC_BUTTON_TABLE_COLUMNS = ['requestTimestamp', 'assetId', 'actions'];

export const BOOKMARKED_PANIC_BUTTON_TABLE_COLUMNS = ['timestamp', 'driverName', 'actions', 'status'];

export const DRIVERID_INPUT_PATTERN = '^(?!.*[_-]{2,})[a-zA-Z0-9_-]*[a-zA-Z0-9][a-zA-Z0-9_-]*$';

export enum DriverType {
  manualAddition = 'manualAddition',
  externalSource = 'externalSource',
}

export enum ManageAction {
  Add = 'add',
  Edit = 'edit',
}

export const AIRecognizedEventTypesMapping = [
  {
    eventType: 'Forward-Collision-Audio-Warning-Heeded',
    eventLabel: 'Forward Collision Warning Heeded',
  },
  {
    eventType: 'Tail-Gating-Warning-Heeded',
    eventLabel: 'Tailgating Warning Heeded',
  },
  {
    eventType: 'Traffic-Speed-Sign-Warning-Heeded',
    eventLabel: 'Speedsign Warning Heeded',
  },
];
