import { DateService } from '@app-core/services/date/date.service';
import { DEVICE_GPS_UPDATE_TIMER_MIN } from '@app-live-view/constants/live-view.constants';
import { BehaviorSubject, Subscription, timer } from 'rxjs';

export interface GpsData {
  latitude: number;
  longitude: number;
  bearing: number;
  speed: number;
  accuracy: number;
}

export enum DeviceState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Amber = 'AMBER',
}

export enum AssetState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE', // AKA "Weak Signal"
  Parked = 'PARKED',
}

export interface DeviceStateInput {
  statusUpdateTimestampUTC?: string;
  lastPingTimestampUTC?: string;
  ongoing?: boolean;
}

export enum LTMessageType {
  Event = 'event',
  Gps = 'gps',
  DeviceHealth = 'deviceHealth',
}

export interface LiveTelematicsMessageValue {
  messageType: LTMessageType;
  sequenceId: string;
  tripId: string;
  driverId: string;
  deviceId: string;
  deviceModel: string;
  assetId: string;
  gpsData: GpsData;
  assetName?: string;
  eventType?: string;
  timestampUTC: string;
  sdkSchema?: number;
  parserSchema?: number;
  ingestionTimestamp?: string;
}

export interface LiveTelematicsMessage {
  key: string;
  partition: number;
  value: LiveTelematicsMessageValue;
}

export interface LiveDevice extends LiveTelematicsMessageValue {
  key: string;
  partition: number;
  timezoneOffset: number;
  timestamp: string;
  ongoing: boolean;
  state: BehaviorSubject<AssetState>;
  receivedLiveTelematicsUpdate: boolean;
  stateTimerSubscription?: Subscription;
  asset?: Asset;
  recordedInfo?: RecordedInfo[];
  currentLocationGeocodeData: BehaviorSubject<any>;
  currentLocationGeocodeDataSub?: Subscription;
  firstLocationGeocodeData: BehaviorSubject<any>;
  firstLocationGeocodeDataSub?: Subscription;
  totalEventCount?: number;
  isAlwaysOnlineEnabled?: boolean;
  driverName?: string;
}

export class IntitialActiveDevice implements LiveDevice {
  public key: string;
  public partition: number;
  public messageType: LTMessageType;
  public tripId: string;
  public driverId: string;
  public deviceId: string;
  public deviceModel: string;
  public ignitionStatus: boolean;
  public gpsData: GpsData;
  public timestampUTC: string;
  public timezoneOffset: number;
  public timestamp: string;
  public sequenceId: string;
  public assetId: string;
  public assetName: string;
  public ongoing: boolean;
  public receivedLiveTelematicsUpdate: boolean;
  public state: BehaviorSubject<AssetState>;
  public stateTimerSubscription: Subscription;
  public asset: Asset;
  public recordedInfo: RecordedInfo[];
  public currentLocationGeocodeData: BehaviorSubject<any>;
  public currentLocationGeocodeDataSub: Subscription;
  public firstLocationGeocodeData: BehaviorSubject<any>;
  public firstLocationGeocodeDataSub: Subscription;
  public totalEventCount?: number;
  public driverName?: string;
  public isAlwaysOnlineEnabled?: boolean;

  constructor(trip: TripAssetDetails, dateService: DateService) {
    const {
      device: { deviceId = '', deviceModel = '' } = {},
      asset: { assetId = '', assetName = '' } = {},
      lastKnownLocation = {} as GpsData,
      isAlwaysOnlineEnabled = false,
      totalEventCount = 0,
      state,
      lastDevicePingTimestampUTC,
    } = trip;

    this.key = deviceId;
    this.partition = -1;
    this.sequenceId = deviceId;
    this.messageType = LTMessageType.Gps;
    this.tripId = trip.tripId;
    this.driverId = trip.driverId;
    this.driverName = trip.driverName;
    this.deviceId = deviceId;
    this.deviceModel = deviceModel;
    this.assetId = assetId;
    this.assetName = assetName;
    this.ignitionStatus = true;
    this.timestampUTC = lastDevicePingTimestampUTC;
    this.timestamp = dateService.getLocalTimestamp(trip.timezoneOffset, lastDevicePingTimestampUTC);
    this.timezoneOffset = trip.timezoneOffset;
    this.ongoing = trip.ongoing;
    this.receivedLiveTelematicsUpdate = false;
    this.gpsData = lastKnownLocation;
    this.totalEventCount = totalEventCount;
    this.isAlwaysOnlineEnabled = isAlwaysOnlineEnabled;
    this.asset = trip.asset;
    this.recordedInfo = trip.recordedInfo;
    this.state = new BehaviorSubject(state);
    if (state === AssetState.Active) {
      this.stateTimerSubscription = timer(DEVICE_GPS_UPDATE_TIMER_MIN * 60 * 1000).subscribe(() => {
        this.state.next(AssetState.Inactive);
      });
    }
    this.currentLocationGeocodeData = new BehaviorSubject({});
    this.firstLocationGeocodeData = new BehaviorSubject({});
  }
}

export interface AssetTags {
  attributeId: number;
  attributeName: string;
  attributeStatus: string;
  attributeType: string;
  entityName: string;
  tagId: number;
  tagName: string;
}

export interface AssetTagsResp {
  assetId: string;
  assetTags: AssetTags[] | null;
}

export interface LatestTripsAssetListResponse {
  rows: TripAssetDetails[];
  aggregateByState: AggregateByState;
  skip: number;
  limit: number;
  totalCount: number;
}

export interface AggregateByState {
  ACTIVE: number;
  INACTIVE: number;
  PARKED: number;
}

export interface TripAssetDetails {
  asset: Asset;
  state: AssetState;
  device: Device;
  tripId: string;
  ongoing: boolean;
  driverId: string;
  driverName: string;
  recordedInfo: RecordedInfo[];
  timezoneOffset: number;
  totalEventCount: number;
  tripDataUploaded: boolean;
  lastKnownLocation: GpsData;
  isAlwaysOnlineEnabled: boolean;
  lastDevicePingTimestampUTC: string;
  assetStatus?: BehaviorSubject<AssetState>;
}

export interface Asset {
  assetId: string;
  assetName?: string;
}

export interface Device {
  model: string;
  deviceModel: string;
  deviceId: string;
}

export interface RecordedInfo {
  source: string;
  resolution: string;
}

export interface LatestTripsAssetListRequest {
  offset: number;
  limit: number;
  startDate: string;
  endDate: string;
  tagIds?: number[];
  assetId?: string;
  state?: string[];
}

export enum LivestreamStatus {
  Started = 'STARTED',
  Unavailable = 'UNAVAILABLE',
  Canceled = 'CANCELED',
  Finished = 'FINISHED',
}

export interface LivestreamStatusUpdateMessage {
  fleetId: string;
  deviceId: string;
  streamRequestId: string;
  status: LivestreamStatus;
  startTimeUTC: string;
  endTimeUTC?: string; // appears only for FINISHED status
}
