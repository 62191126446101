import { API } from '@app-core/constants/api.constants';

export const Durations = [
  {
    title: 'Past 3 Days',
    days: 3,
  },
  {
    title: 'Past Week',
    days: 7,
  },
  {
    title: 'Past Month',
    days: 30,
  },
  {
    title: 'Past 3 Months',
    days: 90,
  },
];

// Trip table
export const TRIP_TABLE_COLUMNS = (customerName = '', isFaceIdEnabled) => [
  'uploadStatus',
  ...(isFaceIdEnabled ? ['driverImage'] : ''),
  'startTime',
  'startLocation',
  'endLocation',
  'driverName',
  'eventCount',
  'tripDistance',
  'tripDuration',
  ...(customerName === 'calamp' ? ['vehicleId'] : ['assetId']),
  'actions',
];
export const TRIPS_TABLE_PAGE_SIZE = 10;
export const DVR_INITIAL_STATUS = {
  pending: 0,
  finished: 0,
  failed: 0,
};
// active drivers table
export const DRIVER_TABLE_PAGE_SIZES = [10, 15, 20, 30];
export const DRIVER_TABLE_COLUMNS = ['driverId', 'driverName', 'eventsPer100Units', 'tripCount', 'tripDistance', 'tripDuration'];

export const POST_DRIVER_UPDATION_AFFECTED_APIS = (driverId: string, tripId: string) => [
  //Trip Details APIs
  `${API.TRIP_DETAILS}?tripId=${tripId}`,
  API.EVENT_DETAILS,

  //Violation APIs
  API.GET_VIOLATIONS,
  API.GET_SEVERE_VIOLATIONS,

  //Driver APIs
  API.GET_DRIVER_EVENT_TREND,
  API.GET_DRIVER_STATS_V2,
  API.GET_DRIVER_TRIPS_V2,
  API.GET_DRIVER_DETAILS_V2(driverId),

  //Fleet APIs
  API.GET_TRIP_LIST_V2,
  API.GET_DRIVER_LIST_V2,
  API.GET_DVR_REQUESTS,
  API.GET_EDVR_REQUESTS,
  API.GET_EXTERNAL_EVENTS,
  API.GET_IMPROVED_DRIVERS,

  //Asset APIS
  API.GET_ASSET_TRIPS_V2,
  API.GET_ASSET_VIOLATIONS,
];

export const DRIVER_UPDATION_LOG_TABLE_COLUMN: string[] = ['requestId', 'requestedBy', 'requestedTime', 'status', 'action'];

export const UPDATION_LOG_CSV_HEADERS = [
  {
    label: 'Asset ID (required, case sensitive)',
    value: 'assetId',
  },
  {
    label: 'Start Time (UTC)',
    value: 'afterTimeUTC',
  },
  {
    label: 'End Time (UTC)',
    value: 'beforeTimeUTC',
  },
  {
    label: 'New Driver ID (required)',
    value: 'newDriverId',
  },
  {
    label: 'Old Driver ID (optional, case sensitive)',
    value: 'oldDriverId',
  },
  {
    label: 'Total Trips',
    value: 'totalTrips',
  },
  {
    label: 'Updated Trips',
    value: 'updatedTrips',
  },
  {
    label: 'Non-updated Trips',
    value: 'ignoredTrips',
  },
  {
    label: 'Comments',
    value: 'comments',
  },
];
