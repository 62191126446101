import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ClientConfigResponse } from '@app-core/models/core.model';
import { DurationRangeService } from '@app-core/services/duration-range/duration-range.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientConfigResolver implements Resolve<ClientConfigResponse> {
  constructor(private durationService: DurationRangeService) {}

  public resolve(): Observable<ClientConfigResponse> {
    return this.durationService.getClientConfig();
  }
}
