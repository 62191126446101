import { Component, Input } from '@angular/core';
import { FooterComponent, FooterComponentData } from '../../footer.model';

@Component({
  selector: 'app-guidpoint',
  templateUrl: './guidpoint.component.html',
  styleUrls: ['./guidpoint.component.scss'],
})
export class GuidpointComponent implements FooterComponent {
  @Input() data: FooterComponentData;
}
