import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app-core/constants/api.constants';
import { DEFAULT_DATA_RETENTION_DAYS, DEFAULT_DURATION_LIST } from '@app-core/constants/constants';
import { ClientConfigResponse, Duration, PortalNotificationEventFlag } from '@app-core/models/core.model';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DurationRangeService {
  private durationList: Duration[] = DEFAULT_DURATION_LIST;

  private _dataRetentionDays: number = DEFAULT_DATA_RETENTION_DAYS;

  private _includePolylinePathInfo: boolean = false;

  private _showPortalNotification: boolean = false;

  private _portalNotificationEventFlags: PortalNotificationEventFlag[];

  public get dataRetentionDays() {
    return this._dataRetentionDays;
  }

  public get showPortalNotification() {
    return this._showPortalNotification;
  }

  public get includePolylinePathInfo() {
    return this._includePolylinePathInfo;
  }

  public get portalNotificationEventFlags() {
    return this._portalNotificationEventFlags;
  }

  public set dataRetentionDays(value: number) {
    this._dataRetentionDays = value;
    this.updateDurationList();
  }

  public set includePolylinePathInfo(value: boolean) {
    this._includePolylinePathInfo = value;
  }

  public set showPortalNotification(value) {
    this._showPortalNotification = value;
  }

  constructor(private http: HttpClient) {}

  public getDurationList(): Duration[] {
    return this.durationList;
  }

  public updateDurationList() {
    this.durationList = this.getCustomDurationList(this.durationList, this.dataRetentionDays);
  }

  public getCustomDurationList(customDurationList: Duration[], dataRetentionPeriod = this.dataRetentionDays): Duration[] {
    let verifiedList = customDurationList.filter((item: Duration) => {
      if (item.days === 'custom' || item.days === 'yesterday') {
        return true;
      } else if (typeof item.days === 'number') {
        return item.days <= dataRetentionPeriod;
      }
      return false;
    });
    return verifiedList;
  }

  public getClientConfig(): Observable<ClientConfigResponse> {
    return this.http.get<ClientConfigResponse>(API.CLIENT_CONFIG).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      tap((res: ClientConfigResponse) => {
        const {
          dataRetention = DEFAULT_DATA_RETENTION_DAYS,
          includePolylinePathInfo = false,
          showPortalNotification = false,
          portalNotificationEventFlags,
        } = res?.data || {};
        this.dataRetentionDays = dataRetention;
        this.includePolylinePathInfo = includePolylinePathInfo;
        this._showPortalNotification = showPortalNotification;
        this._portalNotificationEventFlags = portalNotificationEventFlags;
        // dev testing
        // this._portalNotificationEventFlags = [
        //   'Cornering' , 'Harsh-Acceleration' , 'Harsh-Braking' ,
        //   'Cellphone-Distracted-Driving' , 'Smoking-Distracted-Driving' , 'Drinking-Distracted-Driving' ,
        //   'Distracted-Driving' , 'Lizard-Eye-Distracted-Driving' , 'Texting-Distracted-Driving' ,
        //   'Drowsy-Driving-Detected' , 'MaxSpeedExceeded' , 'Unbuckled-Seat-Belt' , 'Tail-Gating-Detected' , 'Forward-Collision-Warning' ,
        //   'Traffic-STOP-Sign-Violated' , 'Traffic-Light-Violated' , 'Lane-Drift-Found' ,
        //   'Custom-Triggered' , 'PotentialCrash' , 'DVRUpload' , 'Roll-Over-Detected'
        //   ];
      }),
      catchError(() => {
        this.dataRetentionDays = DEFAULT_DATA_RETENTION_DAYS;
        this.includePolylinePathInfo = false;
        this._showPortalNotification = false;
        this._portalNotificationEventFlags = [];
        return of(null);
      })
    );
  }
}
